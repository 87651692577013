.pager {
  background-color: rgba(0, 0, 0, 0.398);
  z-index: 2;
  position: fixed;
  border-radius: 3px;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  display: flex;
}

.pager-input {
  border: 1px solid black;
  max-width: 4rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 50;
  padding-top: 60px;
  box-sizing: border-box;
}

.image-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 50;
}

.backdrop .pdf-image,
.image-backdrop .pdf-image {
  width: 25px;
  height: 25px;
  margin: 0;
  margin-right: 10px;
}

.header {
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.097);
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.097);
}

.file-info {
  display: flex;
  align-items: center;
}

.file-info > span {
  color: white;
  margin-top: 3px;
}

.button-back {
  color: white !important;
  padding: 1px !important;
  margin-right: 22px !important;
  border-radius: 3px !important;
  z-index: 1000 !important;
}

.button-2 {
  color: white !important;
  padding: 1px !important;
  margin-right: 10px !important;
}

.button-3 {
  color: white !important;
  padding: 1px !important;
}

.button-3-disabled {
  color: rgba(255, 255, 255, 0.446) !important;
  padding: 1px !important;
}

.button-3-f {
  margin-right: 20px !important;
}

.button-back:focus,
.button-2:focus,
.button-3:focus {
  outline: 0;
}

.button-back:hover,
.button-2:hover .button-3:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.navigate-page {
  border-left: 1px solid rgba(255, 255, 255, 0.159);
}

.loader {
  background-color: rgba(0, 0, 0, 0.529) !important;
}

.img-preview {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .pdf-doc {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pdf-doc > canvas {
    width: 90% !important;
    height: 90% !important;
  }

  .backdrop {
    overflow: hidden;
  }

  .navigate-page {
    display: flex;
  }

  .img-preview {
    max-width: 90%;
    max-height: 100%;
    object-fit: contain;
  }

  .image-backdrop {
    padding-top: 100px;
  }
}
